import defineComponent from '../../../../components/flight/lib/component';
import React from 'react';
import ReactDom from 'react-dom/client';
import PaperTypeEditor from '../React/Catalog/PaperTypeEditor.tsx';

export  default defineComponent(AdminCatalogPaperTypes);

function AdminCatalogPaperTypes() {
    this.initCatalogPaperTypesEditor = function () {
        const editor = $(this.node);
        const root = ReactDom.createRoot(editor.get(0));
        root.render(
            <PaperTypeEditor
                paperTypeId={editor.data('paper-type-id')}
                substrateTypeId={editor.data('substrate-type-id')}
            />
        )
    }

    this.after('initialize', function () {
        this.initCatalogPaperTypesEditor();
    });
}