export var ApprovalStatus;
(function (ApprovalStatus) {
    ApprovalStatus["APPROVED"] = "APPROVED";
    ApprovalStatus["REJECTED"] = "REJECTED";
})(ApprovalStatus || (ApprovalStatus = {}));
export var InvoiceRefundMethod;
(function (InvoiceRefundMethod) {
    InvoiceRefundMethod["ORIGINAL_PAYMENT_METHOD"] = "ORIGINAL_PAYMENT_METHOD";
    InvoiceRefundMethod["STORE_CREDIT"] = "STORE_CREDIT";
})(InvoiceRefundMethod || (InvoiceRefundMethod = {}));
export var InvoiceRefundJobStatus;
(function (InvoiceRefundJobStatus) {
    InvoiceRefundJobStatus["PENDING_APPROVAL"] = "PENDING_APPROVAL";
    InvoiceRefundJobStatus["QUEUED"] = "QUEUED";
    InvoiceRefundJobStatus["EXECUTING"] = "EXECUTING";
    InvoiceRefundJobStatus["REJECTED"] = "REJECTED";
    InvoiceRefundJobStatus["COMPLETED"] = "COMPLETED";
    InvoiceRefundJobStatus["FAILED"] = "FAILED";
})(InvoiceRefundJobStatus || (InvoiceRefundJobStatus = {}));
