var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, FormGroup, Modal, Row, Spinner, Table } from "react-bootstrap";
import { Field, Form as FormikForm, Formik } from 'formik';
import axios from "axios";
import { t } from "../../../../../assets/i18n/i18n";
import ConfirmationModal from "../TcoConfiguration/components/ConfirmationModal";
import { DisplayWeightUnit } from "../ProductMetaDataEditor/types";
var WeightType;
(function (WeightType) {
    WeightType[WeightType["TEXT"] = 0] = "TEXT";
    WeightType[WeightType["COVER"] = 1] = "COVER";
})(WeightType || (WeightType = {}));
var PaperTypeEditor = function (props) {
    var _a = useState(true), isDataLoading = _a[0], setDataLoading = _a[1];
    var _b = useState([]), weights = _b[0], setWeights = _b[1];
    var _c = useState(null), successMessage = _c[0], setSuccessMessage = _c[1];
    var _d = useState(false), showModal = _d[0], setShowModal = _d[1];
    var _e = useState(null), editIndex = _e[0], setEditIndex = _e[1];
    var _f = useState(false), showDeleteModal = _f[0], setShowDeleteModal = _f[1];
    var _g = useState(DisplayWeightUnit.GSM), displayWeightUnit = _g[0], setDisplayWeightUnit = _g[1];
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        setDataLoading(true);
                        return [4 /*yield*/, getSubstrateTypesData()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        console.error('Error fetching data', error_1);
                        return [3 /*break*/, 4];
                    case 3:
                        setDataLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, []);
    var handleAddOption = function (setFieldValue) {
        setEditIndex(null);
        setFieldValue('modalFields', defaultModalFields);
        setShowModal(true);
    };
    var handleEditWeight = function (index, values, setFieldValue) {
        setEditIndex(index);
        var weight = values.weights[index];
        setFieldValue('modalFields', weight);
        setShowModal(true);
    };
    var handleDeleteWeight = function (index) {
        setEditIndex(index);
        setShowDeleteModal(true);
    };
    var handleModalSave = function (values, setFieldValue) {
        editIndex !== null ? handleSaveEditWeight(values, setFieldValue) : handleSaveNewWeight(values, setFieldValue);
    };
    var doDelete = function (weightId, values, setFieldValue) {
        setDataLoading(true);
        try {
            axios.delete("/api/admin/catalogue/paper-types/".concat(props.paperTypeId, "/weights/").concat(weightId));
        }
        catch (error) {
            console.error('Error deleting weight', error);
        }
        finally {
            setFieldValue('weights', values.weights.filter(function (item) { return item.id != weightId; }));
            setShowModal(false);
            setDataLoading(false);
            setShowDeleteModal(false);
        }
    };
    var handleSaveEditWeight = function (values, setFieldValue) {
        setDataLoading(true);
        try {
            axios.put("/api/admin/catalogue/paper-types/".concat(props.paperTypeId, "/weights/").concat(values.modalFields.id), values.modalFields);
        }
        catch (error) {
            console.error('Error updating weight', error);
        }
        finally {
            var updatedWeights = __spreadArray([], values.weights, true);
            updatedWeights[editIndex] = values.modalFields;
            setFieldValue("weights", updatedWeights);
            setShowModal(false);
            setDataLoading(false);
        }
    };
    var handleSaveNewWeight = function (values, setFieldValue) {
        setDataLoading(true);
        try {
            axios.post("/api/admin/catalogue/paper-types/".concat(props.paperTypeId, "/weights"), values.modalFields);
        }
        catch (error) {
            console.error('Error saving weight', error);
        }
        finally {
            setFieldValue("weights", __spreadArray(__spreadArray([], values.weights, true), [values.modalFields], false));
            setShowModal(false);
            setDataLoading(false);
        }
    };
    var getSubstrateTypesData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("/api/admin/catalogue/paper-types/".concat(props.paperTypeId))];
                case 1:
                    response = _a.sent();
                    setDisplayWeightUnit(response.data.weightUnit || DisplayWeightUnit.GSM);
                    setWeights(response.data.catalogPaperType.weights || []);
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.log('Error fetching catalogue data', error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var defaultModalFields = {
        id: 0,
        gsm: 0,
        caption: '',
        caliper: 0,
        active: false
    };
    var initialValues = {
        substrateType: props.substrateTypeId,
        weights: weights,
        modalFields: defaultModalFields
    };
    if (isDataLoading) {
        return React.createElement(Spinner, { animation: "grow", variant: "primary" });
    }
    return (React.createElement(Formik, { initialValues: initialValues, enableReinitialize: true }, function (_a) {
        var values = _a.values, setFieldValue = _a.setFieldValue;
        return (React.createElement(React.Fragment, null,
            successMessage && (React.createElement(Alert, { variant: "success", onClose: function () { return setSuccessMessage(null); }, dismissible: true }, successMessage)),
            React.createElement("div", { className: "alert alert-warning", role: "alert" }, t("admin.catalogue.dangerousFeature")),
            React.createElement(Row, null,
                React.createElement(Col, null,
                    isDataLoading && React.createElement(Spinner, { animation: "grow", variant: "primary" }),
                    !isDataLoading && !weights &&
                        React.createElement(Alert, { variant: "danger" }, "Error retrieving weight data"))),
            !isDataLoading && weights && (React.createElement(FormikForm, null,
                React.createElement(Table, { bordered: true, className: "mt-3" },
                    React.createElement("thead", null,
                        React.createElement("tr", null,
                            React.createElement("th", null, "Weight ID"),
                            React.createElement("th", null, "GSM"),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement("th", null, "Libs"),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement("th", null, "Type"),
                            React.createElement("th", null, "Caption"),
                            React.createElement("th", null, "Caliper"),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement("th", null, "Microns"),
                            React.createElement("th", null, "Active"),
                            React.createElement("th", null))),
                    React.createElement("tbody", null, values.weights && values.weights.map(function (weight, index) {
                        return (React.createElement("tr", { key: weight.id },
                            React.createElement("td", null, weight.id),
                            React.createElement("td", null, weight.gsm),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement("td", null, weight.libs),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement("td", null, WeightType[weight.type]),
                            React.createElement("td", null, weight.caption),
                            React.createElement("td", null, weight.caliper),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement("td", null, weight.microns),
                            React.createElement("td", null, weight.active ? "Yes" : "No"),
                            React.createElement("td", null,
                                React.createElement(Button, { className: "btn-sm me-2", variant: "primary", onClick: function () { return handleEditWeight(index, values, setFieldValue); } }, "Edit"),
                                React.createElement(Button, { className: "btn-sm", variant: "danger", onClick: function () { return handleDeleteWeight(index); } }, t("admin.misc.delete")))));
                    }))),
                React.createElement(Modal, { show: showModal, onHide: function () { return setShowModal(false); } },
                    React.createElement(Modal.Header, { closeButton: true },
                        React.createElement(Modal.Title, null, editIndex !== null ? 'Edit Weight' : 'Add New Weight')),
                    React.createElement(Modal.Body, null,
                        React.createElement(Form, null,
                            React.createElement(FormGroup, null,
                                React.createElement(Form.Label, null, "Weight Id"),
                                React.createElement(Field, { type: "number", name: "weightId", value: values.modalFields.id, disabled: editIndex !== null, onChange: function (e) {
                                        return setFieldValue('modalFields.id', e.target.value);
                                    }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control" })),
                            React.createElement(FormGroup, { className: "mt-3" },
                                React.createElement(Form.Label, null, "GSM"),
                                React.createElement(Field, { type: "number", name: "weightGsm", value: values.modalFields.gsm, onChange: function (e) {
                                        return setFieldValue('modalFields.gsm', e.target.value);
                                    }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control" })),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement(FormGroup, { className: "mt-3" },
                                    React.createElement(Form.Label, null, "Libs"),
                                    React.createElement(Field, { type: "number", name: "weightLibs", value: values.modalFields.libs, onChange: function (e) {
                                            return setFieldValue('modalFields.libs', e.target.value);
                                        }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control" })),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement(FormGroup, { className: "mt-3" },
                                    React.createElement(Form.Label, null, "Type"),
                                    React.createElement(Form.Select, { value: values.modalFields.type, name: "weightUnit", onChange: function (e) {
                                            return setFieldValue('modalFields.type', parseInt(e.target.value));
                                        } },
                                        React.createElement("option", { value: WeightType.TEXT }, "Text"),
                                        React.createElement("option", { value: WeightType.COVER }, "Cover"))),
                            React.createElement(FormGroup, { className: "mt-3" },
                                React.createElement(Form.Label, null, "Caption"),
                                React.createElement(Field, { type: "string", name: "weightCaption", value: values.modalFields.caption, onChange: function (e) {
                                        return setFieldValue('modalFields.caption', e.target.value);
                                    }, className: "form-control" })),
                            React.createElement(FormGroup, { className: "mt-3" },
                                React.createElement(Form.Label, null, "Caliper"),
                                React.createElement(Field, { type: "number", name: "weightCaliper", value: values.modalFields.caliper, onChange: function (e) {
                                        return setFieldValue('modalFields.caliper', e.target.value);
                                    }, min: 0, step: "any", inputMode: "numeric", className: "form-control" })),
                            displayWeightUnit === DisplayWeightUnit.LIBRE &&
                                React.createElement(FormGroup, { className: "mt-3" },
                                    React.createElement(Form.Label, null, "Microns"),
                                    React.createElement(Field, { type: "number", name: "weightMicrons", value: values.modalFields.microns, onChange: function (e) {
                                            return setFieldValue('modalFields.microns', e.target.value);
                                        }, min: 0, inputMode: "numeric", pattern: "[0-9]*", className: "form-control" })),
                            React.createElement(FormGroup, { className: "mt-3" },
                                React.createElement(Form.Label, null, "Active"),
                                React.createElement(Form.Select, { value: String(values.modalFields.active), name: "weightActive", onChange: function (e) {
                                        return setFieldValue('modalFields.active', e.target.value === 'true');
                                    } },
                                    React.createElement("option", { value: "true" }, "Yes"),
                                    React.createElement("option", { value: "false" }, "No"))))),
                    React.createElement(Modal.Footer, null,
                        React.createElement(Button, { variant: "secondary", onClick: function () { return setShowModal(false); } }, t("admin.refund.close")),
                        React.createElement(Button, { variant: "primary", onClick: function () { return handleModalSave(values, setFieldValue); } }, editIndex !== null ? 'Save Changes' : 'Add Weight'))),
                React.createElement(ConfirmationModal, { show: showDeleteModal, handleClose: function () { return setShowDeleteModal(false); }, handleSubmit: function () { return doDelete(values.weights[editIndex].id, values, setFieldValue); }, closeButtonText: 'Close', content: 'Are you sure you want to delete this weight?', saveButtonText: "Delete", heading: "Delete Weight" }),
                React.createElement(Button, { type: "button", variant: "secondary", onClick: function () { return handleAddOption(setFieldValue); }, className: "m-2 px-4 py-2" }, t("admin.catalogue.addWeight"))))));
    }));
};
export default PaperTypeEditor;
