import regional_EN_GB from "./regional_EN_GB";
import regional_EN_US from "./regional_EN_US";
import regional_EN_CA from "./regional_EN_CA";
import regional_EN_AU from "./regional_EN_AU";
import regional_DE_DE from "./regional_DE_DE";
import regional_EN_DE from "./regional_EN_DE";
import regional_EN_IE from "./regional_EN_IE";
import regional_JA_JP from "./regional_JA_JP";
import regional_EN_JP from "./regional_EN_JP";
import mixam from "./mixam";

const regional = {
    en_gb: regional_EN_GB,
    en_us: regional_EN_US,
    en_ca: regional_EN_CA,
    en_au: regional_EN_AU,
    de_de: regional_DE_DE,
    en_de: regional_EN_DE,
    en_ie: regional_EN_IE,
    ja_jp: regional_JA_JP,
    en_jp: regional_EN_JP
};

export default function getRegional() {
    return regional[mixam.shop.locale.toLowerCase()] || regional.en_gb;
}


